import React from 'react'
import Header from '../layout/Header'
import Footer from '../components/Footer'
import Form from '../components/Form'
import styles from './Contact.module.scss';
export default function Contact() {
  return (
    <>
      <Header />
      <div className={styles.mv}>
        <h1 className={styles.title}>お問い合わせ</h1>
      </div>

      <section className='pt-[8rem] pb-[6rem] max-sm:py-[4rem] '>
        <div className='inner'>
          <p className='text-center leading-loose'>maruttoへのお問い合わせは下記からお願い致します。<br />担当より2営業日以内にご返信いたします。</p>
        </div>
        <Form />
      </section>
      <Footer />
    </>
  )
}
