import React from 'react';
import styles from './CTA.module.scss';
import '../utils.css';

const CTAArea = () => {
  return (
    <>
      <div className={`${styles.ctaArea}`}>
        <p className='text-center'>無制限・完全無料で質問受け付けています！</p>
        <h3 className={`${styles.ctaAreaHeading} text-center max-sm:text-[1.6rem]`}>ホームページのこと、<br /> まずは<span className="text-yellow">気軽に聞いて<br className='isSp' />みませんか？</span></h3>
        <div className="flex gap-9 max-sm:flex-wrap">
          <a href="/contact" className={`${styles.btn} btn w-1/2 mx-auto text-center `}>お問い合わせはこちら</a>
        </div>
      </div>
    </>
  );
};

export default CTAArea;
