import React from 'react'

export default function BackTop() {
  function ScrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div className='fixed bottom-12 right-16 max-sm:right-8 z-15'>
      <button onClick={ScrollTop} className='flex flex-col items-center'>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="44" rx="22" fill="#03A9F4" />
          <path d="M22 29V15M22 15L15 20M22 15L29 20" stroke="white" strokeWidth="3" />
        </svg>
        <p>TOPへ</p>
      </button>
    </div>
  )
}
