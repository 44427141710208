import { React, useState, useEffect } from 'react';
import styles from './Header.module.scss';
import '../utils.css';
import Logo from '../components/Logo';
import { GlobalNavPc, GlobalNavSp } from '../components/GlobalNav';
import HmbMenu from '../components/HmbMenu';
import { Link } from 'react-router-dom';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 50) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // メニューが開いているときにページのスクロールを禁止
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // コンポーネントがアンマウントされたときにスクロール制約を解除
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <>
      <header className={`${styles.header} ${isScroll ? styles.isShow : ''} ${isOpen ? styles.isActive : ''}`}>
        <div className={styles.headerInner}>
          <h1 className={`${styles.logo} `}>
            <Link to={'/'}>
              <Logo />
            </Link>
          </h1>
          <GlobalNavPc />
          <HmbMenu state={isOpen} func={toggleMenu} />
          {isOpen &&
            <GlobalNavSp func={toggleMenu} />
          }
        </div>
      </header>
    </>
  );
};

export default Header;
