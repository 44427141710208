import React from 'react'
import FaqItem from './FaqItem';
import styles from './Faq.module.scss';

export default function FaqList() {
  const faqList = [
    {
      id: 1,
      question: "どんなホームページを制作できますか？",
      answer: "PC・タブレット・スマホで閲覧できるホームページを制作いたします。プランごとにページ数が異なりますが、最低3ページ〜から制作可能でございます。",
    },
    {
      id: 2,
      question: "本当に9,980円だけでできますか？",
      answer: "はい、9,980円でホームページに必要なほとんどの機能を実装させていただきます。別途ご要望があれば、追加費用が発生する場合がございますが、ほとんどのお客様は9,980円~でホームページをお持ちいただけます。",
    },
    {
      id: 3,
      question: "ホームページを作る際にこちらがすることはありますか？",
      answer: "お客様の方でご対応いただくことは、「最初のお問い合わせ」「お申し込み後のお打ち合わせ」「確認」の3点のみです。",
    },
    {
      id: 4,
      question: "契約期間はどれくらいですか？",
      answer: "契約期間は1ヶ月単位の自動更新です。解約時には、解約月の末日までにご連絡いただければ翌月の費用は発生いたしません。",
    },
    {
      id: 5,
      question: "解約料金は発生しますか？",
      answer: "発生いたしません。ただし、1ヶ月前以前の申告の場合、当月の利用料金は発生いたします。",
    },
    {
      id: 6,
      question: "ホームページ公開後の更新はできますか？",
      answer: "基本的に月5回までを更新可能とさせていただいておりますが、突発な更新が必要な場合は柔軟に対応させていただきますのでお気軽にご連絡ください。",
    },
  ];

  return (
    <div>
      <ul className={styles.faqInner}>
        {faqList.map((faqItem) => {
          return (
            <li key={faqItem.id}><FaqItem props={faqItem} /></li>
          );
        })}
      </ul>
    </div>
  )
}
