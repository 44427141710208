import React from 'react'
import styles from '../components/Flow.module.scss';
import Heading from '../components/Heading';
import Flow from '../components/Flow';
import CTAArea from '../components/CTAArea';

export default function FlowSection() {
  return (
    <>
      <section className={`${styles.section}`} id='flow'>
        <div className="inner">
          <Heading
            subHeading={'flow'}
            mainHeading={'制作の流れ'}
          />
          <Flow />
        </div>
      </section>
      <div className="inner">
        <CTAArea />
      </div>
    </>
  )
}
