import React from 'react'
import Heading from '../components/Heading';
import PriceList from '../components/PriceList';
import styles from '../components/Price.module.scss';

export default function priceSection() {
  return (
    <>
      <section className={`${styles.priceSection}`} id='price'>
        <div className="inner">
          <Heading
            subHeading={'price'}
            mainHeading={'料金プラン'}
          />
          <PriceList />
        </div>
      </section>
    </>
  )
}
