import React from 'react';
import '../utils.css';
import styles from './Service.module.scss';
import Heading from '../components/Heading';
import ServiceList from '../components/ServiceList';

function ServiceSection() {

  return (
    <>
      <section className={styles.section} id='service'>
        <div className="inner">
          <Heading subHeading={'service'} mainHeading={'マルットでできること一覧'} />
          <ServiceList />
          <p className={`${styles.text} mt-12 text-center`}>
            その他、ご要望に応じて柔軟なサポートが<br className='isSp' />対応可能です。<br />まずはお気軽にご相談ください。
          </p>
        </div>
      </section>
    </>
  )
}

export default ServiceSection