import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Head from './components/Head';
import Home from './routes/Home';
import Contact from './routes/Contact';


function App() {
  const metadata =
  {
    title: 'marutto | 月額9,980円で始められる定額制のホームページ制作サービス',
    desc: 'maruttoは月額9,980円で始められる定額制のホームページ制作サービスです。初期費用0円、制作費用0円、解約費用も0円でコストを抑えて自社のホームページを持つことができます。新しい事業を始めたいが、コストは掛けたくない。そんな個人事業主や中小企業の経営者様にうってつけのサービスです。',
    url: process.env.REACT_APP_URL,
  }
  return (
    <>
      <Head metadata={metadata} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
