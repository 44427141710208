import React from 'react'
import Header from '../layout/Header';
import MainVisual from '../layout/MainVisual';
import AboutSection from '../layout/AboutSection';
import PointSection from '../layout/PointSection';
import ServiceSection from '../layout/ServiceSection';
import PriceSection from '../layout/priceSection';
import FlowSection from '../layout/FlowSection';
import FaqSection from '../layout/FaqSection';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <>

      <div id='wrap'>
        <Header />
        <MainVisual />
        <AboutSection />
        <PointSection />
        <PriceSection />
        <ServiceSection />
        <FlowSection />
        <FaqSection />
        <Footer />
      </div>
    </>
  )
}


