import React from 'react'
import styles from './HmbMenu.module.scss';

export default function HmbMenu({ state, func }) {
  return (
    <div className={`flex gap-4 items-center ${styles.hmbMenuWrap}`}>
      <a href="/contact" className={`btn ${styles.hmbBtn}`}>お問い合わせ</a>
      <div className={`${styles.hamburgerMenu} ${state ? styles.isActive : ''} `} onClick={func}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
