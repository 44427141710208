import React from 'react';
import '../utils.css';
import styles from './Point.module.scss';
import CTAArea from '../components/CTAArea';

import point01 from "../images/point01.png";
import point02 from "../images/point02.png";
import point03 from "../images/point03.png";


function PointSection() {

  return (
    <>
      <section className='py-12' id='point'>
        <div className="inner">
          <h2 className='heading'>
            <span className='heading__en'>
              point
            </span>
            <span className='heading__ja'>マルットの3つのポイント</span>
          </h2>
        </div>
        <div className={styles.pointList}>
          <div className={styles.pointInner}>
            <h4 className={styles.pointNumber}>POINT<span>01</span></h4>
            <div className={styles.pointItem}>
              <div className={styles.pointImg}>
                <img src={point01} alt="ポイント" className="w-full" />
              </div>
              <div className={styles.pointContent}>
                <h3 className={styles.pointContent__heading}>制作費・初期費・解約費 <span className={`${styles.pointContent__headingAccent} text-red`}>完全<span className="text-number">0</span>円</span></h3>
                <p className={styles.pointContent__text}>マルットは、制作費・初期費用・解約費すべて0円です。<br />
                  必要なのは月額費用だけ。ドメイン代やサーバー代は月額費用内で抑えることができ、初期費用を抑えながらホームページを運営することができます。また解約費用も0円なので、万が一ホームページが必要なくなった場合にも気軽に解約できます。</p>
              </div>
            </div>
          </div>
          <div className={styles.pointInner}>
            <h4 className={styles.pointNumber}>POINT<span>02</span></h4>
            <div className={styles.pointItem}>
              <div className={styles.pointImg}>
                <img src={point02} alt="ポイント２" className="w-full" />
              </div>
              <div className={styles.pointContent}>
                <h3 className={styles.pointContent__heading}>掲載するテキストもおまかせ<br />自社の魅力を最大限にお伝えします。</h3>
                <p className={styles.pointContent__text}>ホームページをつくる際に面倒になるのが、掲載するテキストの作成です。他社サービスの場合、ホームページに掲載するテキストはお客様から支給してもらうことが前提ですが、マルットではテキスト作成もおまかせいただけます。制作前の取材にて事業内容や強みをお伺いし、貴社の魅力を最大限に伝えるテキストを作成します。</p>
              </div>
            </div>
          </div>
          <div className={styles.pointInner}>
            <h4 className={styles.pointNumber}>POINT<span>03</span></h4>
            <div className={styles.pointItem}>
              <div className={styles.pointImg}>
                <img src={point03} alt="ポイント３" className="w-full" />
              </div>
              <div className={styles.pointContent}>
                <h3 className={styles.pointContent__heading}>制作後の運用面もサポート<br />月額費用内で修正・更新します。</h3>
                <p className={styles.pointContent__text}>ホームページは作って終わりではなく、作ってからが重要です。せっかくお金をかけて作るものですから、ただの名刺代わりではもったいないでしょう。自社での積極的な情報発信を行うことで、集客や採用に強いホームページとなります。マルットは情報発信やサイト内のテキスト更新などの運用面も月額費用内でサポートいたします。</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="inner">
        <div className="mt-36 max-sm:mt-24">
          <CTAArea />
        </div>
      </div>
    </>
  )
}

export default PointSection