import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import styles from './GlobalNav.module.scss';
const navMenu = [
  {
    href: "/#about",
    label: "マルットについて"
  },
  {
    href: "/#point",
    label: "マルットのポイント"
  },
  {
    href: "/#service",
    label: "マルットでできること"
  },
  {
    href: "/#price",
    label: "料金プラン"
  },
  {
    href: "/#flow",
    label: "制作の流れ"
  },
  {
    href: "/#faq",
    label: "よくあるご質問"
  }
];
export const GlobalNavPc = () => {
  return (
    <nav className={styles.menu}>
      {navMenu.map((menu, index) => (
        <HashLink smooth to={`${menu.href}`} key={index}>
          {menu.label}
        </HashLink>
      ))}
      <Link to="/contact" className='btn'>お問い合わせ</Link>
    </nav>
  )
}

export const GlobalNavSp = ({ func }) => {
  return (
    <div className={styles.menuSp}>
      <ul className={styles.menuList}>
        {navMenu.map((menu) => {
          return (
            <li key={menu.label}><HashLink smooth to={menu.href} onClick={func}>{menu.label}</HashLink></li>
          );
        })}
        <li>
          <a href="/contact" className='btn'>お問い合わせはこちら</a>
        </li>
      </ul>
    </div>
  );
}
