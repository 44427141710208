import React from 'react';
import styles from './Footer.module.scss';
import BackTop from './BackTop';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <div className="inner">
          <div className={styles.footerContents}>
            <a href="/" className={styles.logo}>
              <Logo fill='#03A9F4' />
            </a>
            <ul className={`${styles.footerNavi}`}>
              <li><HashLink smooth to="/#about" >マルットについて</HashLink></li>
              <li><HashLink smooth to="/#point" >マルットのポイント</HashLink></li>
              <li><HashLink smooth to="/#service" >マルットでできること</HashLink></li>
              <li><HashLink smooth to="/#price" >料金プラン</HashLink></li>
              <li><HashLink smooth to="/#flow" >制作の流れ</HashLink></li>
              <li><HashLink smooth to="/#faq" >よくあるご質問</HashLink></li>
              <li><Link to="/contact" >お問い合わせ</Link></li>
              <li><a href="https://7spices.jp" target='_blank' rel='noopener noreferrer'>運営会社</a></li>
            </ul>
          </div>
          <hr className={styles.hr} />
          <p className={styles.copyright}>
            © 2023 <a href="/">marutto</a> All Rights Reserved.
          </p>
        </div>
      </footer>
      <BackTop />

    </>
  )
}
