import React from 'react'
import '../utils.css';

function Heading({subHeading, mainHeading}) {
  return (
    <h2 className='heading'>
    <span className='heading__en'>
      {subHeading}
    </span>
    <span className='heading__ja'>{mainHeading}</span>
  </h2>
  )
}

export default Heading