import React from 'react'
import styles from './Accordion.module.scss';
import { useState } from 'react';

export default function Accordion({ question, answer, number }) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.accordion}`}>
      <div className={`${styles.question} ${isOpen && styles.isOpen}`} onClick={toggleAccordion}>
        <p className={styles.questionWrap}>
          <span className={styles.number}>Q{number}</span>
          <span className={styles.title}>{question}</span>
        </p>
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.45946 10L0 2.33333L2.27027 0L12 10L2.27027 20L0 17.6667L7.45946 10Z" fill="#03A9F4" />
        </svg>
      </div>
      {isOpen && <div className={styles.answer}>
        <p>
          {answer}
        </p>
      </div>}
    </div>
  )
}
