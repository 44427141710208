import React from 'react'
import ServiceItem from './ServiceItem';


export default function ServiceList() {
  const Services = [
    {
      id: 1,
      title: '常時SSL化',
      img: '/images/ssl.png',
    },
    {
      id: 2,
      title: '内部SEO最適化',
      img: '/images/seo.png',
    },
    {
      id: 3,
      title: '独自ドメインメール',
      img: '/images/envelope.png',
    },
    {
      id: 4,
      title: 'Googleマップ設置',
      img: '/images/map.png',
    },
    {
      id: 5,
      title: 'CMS機能',
      img: '/images/cms.png',
    },
    {
      id: 6,
      title: 'Googleアナリティクス連携',
      img: '/images/glaph.png',
    },
    {
      id: 7,
      title: 'LINEで無制限サポート',
      img: '/images/support.png',
    },
    {
      id: 8,
      title: '公開後のサイト更新',
      img: '/images/design.png',
    },
  ];

  return (
    <>
      <ul className='grid grid-cols-4 gap-8 max-md:grid-cols-2 max-md:gap-5'>
        {Services.map((service) => {
          return (
            <li key={service.id} className=''>
              <ServiceItem title={service.title} img={service.img} />
            </li>
          );
        })
        }
      </ul>
    </>

  )
}
