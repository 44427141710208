import React from 'react'
import styles from './Faq.module.scss';
import Accordion from './Accordion';

export default function FaqItem({props}) {
  return (
    <div className={styles.faqItem}>
      <Accordion question={props.question} answer={props.answer} number={props.id}  />
    </div>
  )
}
