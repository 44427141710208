import React from 'react'
import PriceItem from './PriceItem';

export default function PriceList() {
  const priceList = [
    {
      id: 1,
      plan: 'ライトプラン',
      planLabel: 'Light',
      price: '9,980',
      function: [
        '基本ページ3P作成',
        '常時SSL化',
        '独自ドメインメール',
        '月5回程度の更新',
        'スマホ最適化',
        '内部SEO対策'
      ],
      recommend: false,
    },
    {
      id: 2,
      plan: 'スタンダードブラン',
      planLabel: 'Standard',
      price: '14,980',
      function: [
        '基本ページ5P作成',
        '常時SSL化',
        '独自ドメインメール',
        '月5回程度の更新',
        'スマホ最適化',
        '内部SEO対策',
        'ニュース機能',
        'Googleマップ設置',
        'お問い合わせフォーム設置'
      ],
      recommend: true,
    },
    {
      id: 3,
      plan: 'プレミアムプラン',
      price: '20,000',
      planLabel: 'Premium',
      function: [
        '基本ページ7P作成',
        '常時SSL化',
        '独自ドメインメール',
        '月5回程度の更新',
        'スマホ最適化',
        '内部SEO対策',
        'ニュース機能',
        'お問い合わせフォーム設置',
        'Googleマップ設置',
        'Googleアナリティクス設置',
      ],
      recommend: false,
    },
  ]

  return (
    <ul className='grid grid-cols-3 gap-8 max-sm:grid-cols-1 max-sm:gap-12'>
      {priceList.map((price) => {
        return (
          <li key={price.id}>
            <PriceItem props={price} key={price.id} />
          </li>
        );
      })}
    </ul>
  )
}
