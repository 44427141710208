import React from 'react'
import styles from './mainvisual.module.scss';
import mvImg from '../images/mv-img.png';
import point01 from '../images/mv-point01.png';
import point02 from '../images/mv-point02.png';
import point03 from '../images/mv-point03.png';
import { Link } from 'react-router-dom';
const pointsImg = [
  {
    alt: '制作費0円',
    image: point01,
  },
  {
    alt: '初期費0円',
    image: point02,
  },
  {
    alt: '解約費0円',
    image: point03,
  },
]

export default function MainVisual() {
  return (
    <>
      <div id='top' className={`${styles.mainVisual} py-12`}>
        <div className={`${styles.inner}`}>
          <div className="flex items-center max-sm:flex-col">
            <div className='flex flex-col gap-5 items-start w-[50%] max-sm:w-full max-sm:gap-3'>
              <div className={`${styles.contents} w-full`}>
                {
                  pointsImg.map((point, index) => {
                    return (
                      <p key={index}>
                        <img src={point.image} alt={point.alt} />
                      </p>
                    )
                  })
                }
              </div>
              <div className="w-full max-sm:text-center">
                <h1 className={`${styles.mvTitle}`}>
                  月額<span className={`${styles.price}`}><span className={`${styles.textNumber} text-number`}>9,980</span>円</span>から<br /><span className={styles.text}>自社のホームページが持てる！</span>
                </h1>
                <p className={`${styles.mvText}`}>必要なのは月々9,980円だけ。<br />思い通りのホームページが作れます。</p>
              </div>
              <div className={`${styles.button} w-full`}>
                <Link to="/contact" className='py-6 btn w-2/3 block text-center max-sm:w-full'>まずはお問い合わせ</Link>
              </div>
            </div>
            <div className='text-center w-[50%] max-sm:w-full'>
              <img src={mvImg} alt="" className='mx-auto' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
