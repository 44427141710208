import React from 'react';
import '../utils.css';
import image1 from '../images/trouble1.png';
import image2 from '../images/trouble2.png';
import image3 from '../images/trouble3.png';
import styles from './About.module.scss';
import Heading from '../components/Heading';
import '../utils.css';

function AboutSection() {
  return (
    <>
      <section className={styles.section} id='about'>
        <div className="inner">
          <Heading subHeading={'about'} mainHeading={'マルットについて'} />
          <div className={styles.description}>
            <p className='mb-8'>マルットは、サブスクリプション型のホームページサービスです。<br />
              必要なのは月々9,980円だけ。</p>
            <p className='mb-8'>
              ホームページを作るとなると、ドメインやサーバーの準備から
              写真の用意などめんどくさいことが多いですよね。
            </p>
            <p className='mb-8'>その「めんどくさい」はマルットにすべてお任せ。<br />
              用意するものは一切ありません。完全オーダーメイドのハイクオリティなホームページを制作します。</p>
          </div>
        </div>
      </section>
      <section className={styles.trouble}>
        <div className="inner">
          <Heading subHeading={'onayami'} mainHeading={'こんなお悩みはありませんか？'} />
          <div className={styles.troubleList}>
            <div className={styles.troubleItem}>
              <div><img src={image1} alt="" className='max-h-[20rem] w-auto' /></div>
              <div><p>ホームページを作りたいが<br />制作会社に頼むと費用が高い</p></div>
            </div>
            <div className={`${styles.troubleItem} flex flex-col gap-12 items-center justify-around w-4/12 text-center`}>
              <div><img src={image2} alt="" className='max-h-[20rem] w-auto' /></div>
              <div><p>ホームページに掲載するテキストを考えたり、<br className={`isPcTab`} />めんどうなことが多い</p></div>
            </div>
            <div className={`${styles.troubleItem} flex flex-col gap-12 items-center justify-around w-4/12 text-center`}>
              <div><img src={image3} alt="" className='max-h-[20rem] w-auto' /></div>
              <div><p>制作会社に頼むと運用面で<br />追加コストが掛かる</p></div>
            </div>
          </div>
        </div>
        <div className={styles.space}>
          <p>そのお悩み<br /><span className={styles.wave}>まるっと</span>解決します！</p>
        </div>
        <div className="inner">
          <p className='mt-[8rem] text-center leading-[2] text-[1.8rem] max-sm:text-[1.4rem]'>
            maruttoは、初期費用をグッと抑えて自社のホームページを制作することができます。<br />
            原稿作成から担当するので、面倒な作業をおまかせできます。制作後の運用サポートもおまかせください。
          </p>
        </div>
      </section>

    </>
  )
}

export default AboutSection