import React from 'react'
import styles from '../components/Faq.module.scss';
import Heading from '../components/Heading';
import FaqList from '../components/FaqList';

export default function FaqSection() {
  return (
    <section className={`${styles.section}`} id='faq'>
      <div className="inner">
        <Heading
        subHeading={'faq'}
        mainHeading={'よくあるご質問'}
        />
        <FaqList />
      </div>
    </section>
  )
}
