import React from 'react'
import styles from './Price.module.scss';

export default function PriceItem( {props} ) {

  return (
    <div className={`${styles.priceItem}` }>
      <h3 className={`${styles.priceHeading} ${props.recommend ? styles.recommend : ''}`}>
        <span className={`${styles.priceHeadingSub}`}>{props.planLabel}</span>
        <span className={`${styles.priceHeadingMain}`}>{ props.plan}</span>
      </h3>
      <div className={`${styles.priceContents}`}>
        <p className={`${styles.priceLabel}`}>月額利用料</p>
        <p className={`${styles.price} `}><span className='text-number'>{props.price}</span>円</p>
        <div className={`${styles.priceFunction}`}>
          <h4 className={`${styles.priceFunctionHeading}`}>
            主な機能
          </h4>
          <ul className={`${styles.priceFunctionList}`}>
            {props.function.map((item, index) => {
              return (
                <li key={index}>{ item }</li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
