import React from 'react';
import styles from '../layout/Service.module.scss';


export default function ServiceItem({ img, title }) {
  return (
    <div className={`${styles.ServiceItem}`}>
      <div className={`${styles.ServiceItem__Img}`}>
        <img src={img} alt="" className='mx-auto' />
      </div>
      <h3 className={styles.ServiceItem__Heading}>
        {title}
      </h3>
    </div>
  )
}
