import React from 'react'
import { Helmet } from 'react-helmet'

export default function Head({ metadata }) {
  const { title, desc, url } = metadata;
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet='urf-8' />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={desc} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={`${url}/ogp.png`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="marutto" />
      <meta name="google-site-verification" content="HKo_oaXNDSsvQ6BMA5p2-m6Q0wwC3GAG2SvdgOENsmw" />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={`${url}/ogp.png`} />
      <meta name='twitter:site' content='tw' />
      <link rel="canonical" href={url} />
    </Helmet>
  )
}
