import styles from './Flow.module.scss';
import './swiper.scss';

import flow1 from '../images/flow1.png';
import flow2 from '../images/flow2.png';
import flow3 from '../images/flow3.png';
import flow4 from '../images/flow4.png';
import flow5 from '../images/flow5.png';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules';

export default function Flow() {
  const flowList = [
    {
      step: 1,
      title: "お問い合わせ",
      img: flow1,
      description: "抱えている課題からお悩みなどをヒアリングいたします。制作の依頼でなくとも構いませんのでまずはお問い合わせください。"
    },
    {
      step: 2,
      title: "お申し込み",
      img: flow2,
      description: "ご契約内容にご納得いただきましたらお申し込みくださいませ。その後、必要なものやお打ち合わせの日程などをご相談いたします。"
    },
    {
      step: 3,
      title: "取材",
      img: flow3,
      description: "理想のホームページをつくるためにお打ち合わせのお時間を1度設けさせていただきます。理念からイメージカラー、ホームページに掲載したい要素などをお伺いします。"
    },
    {
      step: 4,
      title: "制作",
      img: flow4,
      description: "ヒアリング後、実際の制作に入ります。制作後に実際にスマホやPCで確認できるようにサンプルのホームページを確認いただきます。"
    },
    {
      step: 5,
      title: "納品",
      img: flow5,
      description: "制作物にご納得いただけましたらサイトを公開し納品となります。サイト公開後も運用のサポートなどをさせていただきます。"
    },
  ];

  return (
    <>
      <div className={styles.flow}>
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          id='swiper'
          navigation
          breakpoints={
            {
              540: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              }
            }
          }
        >
          {flowList.map((flowItem) => {
            return (
              <SwiperSlide key={flowItem.step} className={`${styles.swiperBox}`}>
                <p className={styles.boxLabel}>STEP{flowItem.step}</p>
                <div className='my-8'><img className='mx-auto' src={flowItem.img} alt="" /></div>
                <h4 className={styles.boxTitle}>{flowItem.title}</h4>
                <p className={styles.boxDescription}>{flowItem.description}</p>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}
